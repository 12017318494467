<template lang="pug">
    .main-wrapper.cirurgia-salvar

        BuscarProcedimento(:optionsEspecialidades='options.especialidades')

        Dialog.dialogRemover(header="Remover procedimento" :visible.sync='dialogRemover' :modal='true')
            form(v-if='dialogRemover_data')
                p.ta-center Deseja remover o procedimento?
                .ta-center.mt-4
                    Button.p-button-danger(label='Remover' type='button' @click='removerProcedimento(dialogRemover_data._idx)')

        Dialog.dialogHistorico(header="Histórico do paciente" :visible.sync='dialogHistorico' :modal='true')
            .ta-center.waitingHistorico(v-if='waitingHistorico')
                ProgressSpinner(strokeWidth='2')
            div(v-else-if='historico.length')

        Dialog.agendas(header="Agenda" :visible.sync='dialogAgenda' @hide='onHideAgendamentos()' :modal='true')
            form
                .p-grid.p-fluid.p-align-start
                    .p-col-12.p-md-12
                        .p-grid.p-fluid.p-align-end
                            //- .p-col-12.p-md-12(:class="{ 'form-group--error': false}")
                            //-     label.form-label Especialidade:
                            //-     Dropdown(v-model='especialidadeSelecionada' appendTo="body" :options='options.especialidades' :filter="true" dataKey='value'
                            //-             optionLabel='text' optionValue='value', @change='selsectEspecialidade()')
                            //- .p-col-12.p-md-12(:class="{ 'form-group--error': false}")
                            //-     label.form-label Agenda:
                            //-     ProgressBar(v-if='waitingAgenda' mode="indeterminate")
                            //-     MultiSelect(v-else v-model='agendaSelecionada' appendTo="body" :options='options.agendas' dataKey='value' optionLabel='text' optionValue='value')
                            //- .p-col-12.p-md-12(:class="{ 'form-group--error': false}")
                            //-     Button(label='Adicionar' type="button" icon="jam jam-plus" @click="AdicionarEspecialidade()" style="width:30%")
                            .p-col-12.p-md-12(v-if="agendasList?.length" :class="{ 'form-group--error': false}")
                                DataTable.datatable(:value='agendasList')
                                    Column(headerStyle='width: 20%' bodyStyle='overflow-wrap: break-word; text-align: center;' field='value' header='Código')
                                    Column(headerStyle='width: 80%' bodyStyle='overflow-wrap: break-word;' field='text' header='Especialista')
                                    //- Column(headerStyle='width: 20%' header="Ações")
                                    //-     template(#body='props')
                                    //-         .ta-center
                                    //-             Button.p-button-raised.p-button-rounded.p-button-danger(
                                    //-                 v-tooltip.top="'Apagar'"
                                    //-                 icon="jam jam-minus-circle"
                                    //-                 type="button"
                                    //-                 @click='excluirEspecialidade(props.data)')
                            //- .p-col-12.p-md-12.ta-right
                            //-     Button(label='Salvar' @click='onSaveAgendas()' icon='jam jam-check' type="button" style='max-width:160px')


        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "pacotes" }'> Pacotes</router-link> /
                            <b>{{ model.id ? 'Editar' : 'Adicionar' }}</b>

            // form(@submit.prevent='handleSubmit()')
            ProgressBar(v-if='waitingPacote' mode="indeterminate")
            div(v-else)
                Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } Pacote`")
                    .p-grid.p-fluid.p-align-start
                        .p-col-12.p-md-12
                            .p-grid.p-fluid.p-align-end
                                .p-col-12.p-md-6(:class="{ 'form-group--error': false}")
                                    label.form-label Código:
                                    InputText(type='text' v-model='model.data_pacote.codigo')

                                .p-col-12.p-md-6(:class="{ 'form-group--error': false}")
                                    label.form-label Descrição:
                                    InputText(type='text' v-model='model.data_pacote.descricao')
                                    .feedback--errors(v-if='submitted && !model.descricao_valida')
                                        .form-message--error(v-if="!model.data_pacote.descricao.required") <b>Descrição</b> é obrigatório.

                                // .p-col-12.p-md-4
                                //     label.form-label Multi Estabelecimento:
                                //     InputSwitch(type="boolean" v-model='model.multi_estabelecimento' :disabled='model.id != 0' @change='changeMulti()')

                                .p-col-12.p-md-3(:class="{ 'form-group--error':false }")
                                    label.form-label Sigla:
                                    InputText(type='text' v-model='model.data_pacote.sigla')

                                .p-col-12.p-md-3(:class="{ 'form-group--error': sinonimo_error }")
                                    label.form-label Sinônimos:
                                    InputText(type='text' v-model='model.data_pacote.sinonimo' @change='sinonimo_error = false')
                                    .feedback--errors(v-if='sinonimo_error')
                                         .form-message--error <b>Sinônimo</b> é obrigatório.

                                .p-col-12.p-md-3(:class="{ 'form-group--error': submited && !model.ie_tipo_pacote }")
                                    label.form-label Tipo de Pacote:
                                    Dropdown(v-model='model.ie_tipo_pacote' :options='options.ie_tipo_pacote' dataKey='value'
                                        optionLabel='label' optionValue='value')

                                .p-col-12.p-md-3(:class="{ 'form-group--error': false }")
                                    label.form-label Subtipo de Pacote:
                                    Dropdown(v-model='model.ie_subtipo' :disabled="model.ie_tipo_pacote != 'P'" :options='options.ie_subtipo' dataKey='value'
                                        optionLabel='label' optionValue='value')

                    .p-grid.p-fluid.p-align-start
                        .p-col-12.p-md-12
                            .p-grid.p-fluid.p-align-end
                                .p-col-12.p-md-6
                                    label.form-label Preparo:
                                    Textarea(v-model='model.preparo' :autoResize="true" rows="4")

                                .p-col-12.p-md-6( :class="{ 'form-group--error': false }")
                                    label.form-label Termo de aceite:
                                    Textarea(v-model='model.termo' :autoResize="true" rows="4")

                        .p-col-12
                            label.form-label Observação:
                            Textarea(v-model='model.obs' :autoResize="true" rows="4")

                    ProgressBar(v-if="waitingProcedimentos" mode="indeterminate")
                    Panel.datatable-panel(v-else header='Procedimentos')
                        DataTable.datatable(:value='model.showItemList')
                                Column( bodyStyle='overflow-wrap: break-word;' field='nm_estabelecimento' header='Estabelecimento').ta-center
                                Column( field='cd_cbhpm' header='Código')
                                Column( bodyStyle='overflow-wrap: break-word;' field='nm_procedimento' header='Procedimento')
                                Column(header='Qtd' bodyStyle='text-align: center')
                                    template(#body='props')
                                        CustomInputNumber(v-if='props.data.ie_grupo != "C"' v-model='props.data.qtd' showButtons @input="onChangeQtd(props.data)" )
                                        p(v-else) {{ props.data.qtd }}
                                Column(field='valor_original' header='Valor Original')
                                Column( field='nr_valor_negociado' header='Valor Negociado')
                                Column( field='nr_valor_tarifa' header='Taxa Medclub')
                                Column(header="Ações")
                                    template(#body='props')
                                        .ta-center
                                            Button.p-button-raised.p-button-rounded.mr-1(
                                                v-if='props.data.cd_agenda?.length'
                                                v-tooltip.top="'Agendas'"
                                                icon="jam jam-medical"
                                                @click='onSelectAgenda(props.data)'
                                                type="button"
                                            )
                                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                                v-tooltip.top="'Apagar'"
                                                icon="jam jam-minus-circle"
                                                @click='onDeleteItem(props.data.id)'
                                                type="button"
                                            )
                        .ta-center.mt-4
                            Button(
                            v-tooltip.top="'Buscar'"
                            label="Buscar procedimento"
                            icon="jam jam-search"
                            @click="model.dialogProcedimento=true; model.estabelecimentos_selecionados = []; model.procedimentos_selecionado = null;"
                            type='button')

                    Panel.datatable-panel(header='Vigência' style='margin-top: 30px;')
                        .p-grid.p-fluid.p-align-start
                            .p-col-12.p-md-12
                                .p-grid.p-fluid.p-align-end
                                    .p-col-12.p-md-4
                                        label.form-label Valor Original:
                                        InputNumber(:value='preco_original' disabled mode="currency" currency="BRL" )

                                    .p-col-12.p-md-4
                                        label.form-label   Valor Estabelecimento:
                                        InputNumber(v-model='valor_estabelecimento' disabled mode="currency" currency="BRL" )

                                    .p-col-12.p-md-4
                                        label.form-label Valor Medclub:
                                        InputNumber(v-model='valor_medclub' disabled mode="currency" currency="BRL" )

                                    .p-col-12.p-md-6
                                        label.form-label Preço:
                                        InputNumber(v-model='preco_final' disabled mode="currency" currency="BRL" )

                                    .p-col-12.p-md-6
                                        label.form-label Desconto:
                                        InputNumber(v-model='desconto_total' disabled mode="currency" currency="BRL" )

                                    .p-col-12.p-md-6
                                        label.form-label Tipo de desconto Medclub:
                                        Dropdown(v-model='model.preco.tipo_desconto_med' :options='options.tipo_desconto' dataKey='value'
                                        optionLabel='label' optionValue='value' :disabled='model.itemList.length == 0')

                                    .p-col-12.p-md-6
                                        label.form-label Desconto Medclub:
                                        CustomInputNumber(
                                            v-if="model.preco.tipo_desconto_med == 'PC'"
                                            suffix="%"
                                            v-model='model.preco.desconto_med'
                                            :disabled='model.itemList.length == 0'
                                            :min='0'
                                            :max='100'
                                        )

                                        CustomInputNumber(
                                            v-else
                                            :disabled="model.preco.tipo_desconto_med != 'VR' || model.itemList.length == 0"
                                            mode="currency"
                                            currency="BRL"
                                            v-model='model.preco.desconto_med'
                                            :max='valor_medclub'
                                            :min='0'
                                        )

                                    .p-col-12.p-md-6
                                        label.form-label Tipo de Desconto Parceiro Medclub:
                                        Dropdown(v-model='model.preco.tipo_desconto_parc' :options='options.tipo_desconto' dataKey='value'
                                        optionLabel='label' optionValue='value' :disabled='model.itemList.length == 0')

                                    .p-col-12.p-md-6
                                        label.form-label Desconto Parceiro Medclub:
                                        CustomInputNumber(
                                            v-if="model.preco.tipo_desconto_parc == 'PC'"
                                            suffix="%"
                                            v-model='model.preco.desconto_parc'
                                            :disabled='model.itemList.length == 0'
                                            :min='0'
                                            :max='100'
                                        )

                                        CustomInputNumber(
                                            v-else
                                            :disabled="model.preco.tipo_desconto_parc != 'VR' || model.itemList.length == 0"
                                            mode="currency"
                                            currency="BRL"
                                            v-model='model.preco.desconto_parc'
                                            :max='valor_estabelecimento'
                                            :min='0'
                                        )

                                    .p-col-12.p-md-6
                                        label.form-label Data Inicial de Vigência:
                                        .p-inputgroup
                                            Calendar( v-model='model.preco.inicio_vig' dateFormat="dd/mm/yy" :locale="ptbr"
                                                :manualInput='false' :minDate='getMinDate()' :disabled='model.itemList.length == 0')
                                            Button(label='Hoje' type='button' icon='jam jam-calendar' @click='setMinDate()' :disabled='model.itemList.length == 0')
                                            Button.p-button-danger(icon='jam jam-rubber' @click="() => {model.preco.inicio_vig = null; model.preco.fim_vig = null}")
                                        .feedback--errors(v-if='!model.inicio_vigencia_valido')
                                            .form-message--error Intervalo de vigência inválido.

                                    .p-col-12.p-md-6
                                        label.form-label Data Final de Vigência:
                                        .p-inputgroup
                                            Calendar( v-model='model.preco.fim_vig' dateFormat="dd/mm/yy" :locale="ptbr"
                                                :manualInput='false' :minDate='model.preco.inicio_vig' :disabled='model.itemList.length == 0')
                                            Button(label='Máxima' type='button' icon='jam jam-calendar' @click='model.preco.fim_vig = calcularMaximo(model.preco.inicio_vig)' :disabled='model.itemList.length == 0 || !model.preco.inicio_vig')
                                            Button.p-button-danger(icon='jam jam-rubber' @click="() => {model.preco.fim_vig = null}")
                                        .feedback--errors(v-if='!model.fim_vigencia_valido')
                                            .form-message--error Intervalo de vigência inválido.

                        .ta-center.my-4(v-if='!model.editPriceItem')
                            Button(
                            v-tooltip.top="'Adicionar vigência'"
                            label="Adicionar preço"
                            icon="jam jam-plus"
                            @click='onAddPrice()'
                            :disabled='model.itemList.length == 0')
                        .ta-center.my-4(v-if='model.editPriceItem')
                            Button.mx-2(
                            v-tooltip.top="'Salvar'"
                            label="Salvar"
                            icon="jam jam-plus"
                            @click='onSaveEdit()'
                            :disabled='model.itemList.length == 0')

                            Button.mx-2(
                            v-tooltip.top="'Cancelar Edição'"
                            label="Cancelar Edição"
                            icon="jam jam-undo"
                            @click='onCancelAdd()'
                            :disabled='model.itemList.length == 0')

                        div(v-if='model.priceList.length == 0')
                            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhuma vigência adicionada
                        DataTable.datatable(v-else :value='model.priceList')
                            Column(bodyStyle='overflow-wrap: break-word; text-align: center;' field='estabelecimento' header='Estabelecimento')
                            Column(field='vigencia' header='Vigência' bodyStyle='text-align: center;' )
                            Column(field='desconto_medclub' header='Desconto Medclub' bodyStyle='text-align: center;')
                            Column(field='desconto_parceiro' header='Desconto Parceiro' bodyStyle='text-align: center;')
                            Column(header='Taxa Medclub')
                                template(#body='props')
                                    .ta-center
                                        p(v-if='props.data.novo_desconto_medclub != props.data.taxa_medclub' style='text-decoration: line-through; color: gray; font-size: 90%') {{props.data.taxa_medclub}}
                                        p  {{props.data.novo_desconto_medclub}}
                            Column(header='Valor Negociado')
                                template(#body='props')
                                    .ta-center
                                        p(v-if='props.data.novo_desconto_parceiro != props.data.valor_negociado' style='text-decoration: line-through; color: gray; font-size: 90%') {{ props.data.valor_negociado}}
                                        p {{props.data.novo_desconto_parceiro}}
                            Column(field='preco_original' header='Preço Original' bodyStyle='text-align: center;')
                            Column(bodyStyle='overflow-wrap: break-word; text-align: center;' field='preco' header='Preço de Venda')
                            Column(header="Ações")
                                template(#body='props')
                                    .ta-center
                                        Button.p-button-raised.p-button-rounded.mx-1(
                                            v-tooltip.top="'Editar'"
                                            icon="jam jam-write"
                                            @click='onEditPriceItem(props.data)'
                                        )
                                        Button.p-button-raised.p-button-rounded.p-button-danger(
                                            v-tooltip.top="'Apagar'"
                                            icon="jam jam-minus-circle"
                                            @click='onDeletePriceItem(props.data.vigencia)'
                                        )


                    .p-grid.p-fluid.p-align-end.mt-2
                        .p-col-12.p-md-9
                        .p-col-12.p-sm-3.p-md-3.ta-center
                            ProgressSpinner(v-if='waitingSave' strokeWidth='4' style='width:35px; height:35px')
                            Button(v-else label='Salvar' :disabled='waiting', @click='handleSubmit()')



                    .p-col-6.mt-3
                        span <b>Usuario que criou: </b> {{ model.nm_usuario_cri }}
                    .p-col-6
                        span <b>Data da criação: </b> {{ model.dt_criado_f }}
                    .p-col-6
                        span <b>Usuario que editou: </b> {{ model.nm_usuario_edi }}
                    .p-col-6
                        span <b>Data da atualização: </b> {{ model.dt_atualizado  }}
</template>

<style lang="scss">
.p-multiselect-panel {
    position: absolute;
    z-index: 9999;
    height: auto;
    width:15%;
}
.p-dropbox-panel {
    position: absolute;
    z-index: 2;
}
.p-inputnumber-input.p-inputtext.p-component {
    width: 40%;
}

.cirurgia-salvar {
    .p-dialog {
        overflow: visible;
        width: 98%;
        max-width: 720px;
    }

    .card-procedimento {
        font-size: 0.96em;
        .p-col-1,
        .p-col-2,
        .p-col-4,
        .p-col-10,
        .p-col-12 {
            padding: 0.2em;
        }
        .p-inputtext {
            font-size: 0.96em;
            padding: 0.2em;
        }
    }

    .dialogHistorico {
        max-width: 98%;
        min-width: 360px;
        max-height: 480px;
    }

    .agendas {
        width: 40%;
    }

    .dialogProcedimento {
        max-width: 60%;
        min-width: 360px;
        max-height: 480px;
    }

    .waitingCirurgias-wrapper {
        text-align: center;
        padding: 80px 0;
        .p-progress-spinner {
            width: 60px;
            height: auto;
        }
    }
    .waitingOrcamento {
        width: 27px;
        height: auto;
    }
    .waitingHistorico {
        padding: 40px 0;
        .p-progress-spinner {
            width: 60px;
            height: auto;
        }
    }
    .aux-1 {
        text-align: center;
        @media all and (max-width: 576px) {
            text-align: left;
        }
    }
    .p-inputtext[disabled="disabled"] {
        color: #000;
        font-weight: 600;
        background-color: #ddd;
    }
    .p-picklist-source-controls,
    .p-picklist-target-controls {
        display: none;
    }
    .p-picklist-source-wrapper,
    .p-picklist-target-wrapper {
        width: 45%;
        @media all and (max-width: 576px) {
            width: 100%;
        }
    }
    .p-picklist-buttons-cell {
        text-align: center;
        .p-button {
            display: none !important;
            &:first-of-type,
            &:nth-of-type(3) {
                display: inline-block !important;
            }
        }
    }
    .text-box {
        margin: 0;
        font-size: 16px;
    }
    .input-sexo .p-button {
        width: 50% !important;
    }
    .p-dropdown-panel {
        width:15%;
        position: absolute;
        z-index: 1110 !important;
        height: auto;
}
}
</style>

<script>
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import Panel from "primevue/panel";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import InputMask from "primevue/inputmask";
import InputSwitch from "primevue/inputswitch";
import Password from "primevue/password";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import SelectButton from "primevue/selectbutton";
import Tooltip from "primevue/tooltip";
import PickList from "primevue/picklist";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Paginator from "primevue/paginator";
import Dialog from "primevue/dialog";
import Card from "primevue/card";
import Calendar from "primevue/calendar";
import MultiSelect from "primevue/multiselect";
import Textarea from "primevue/textarea";
import CustomInputNumber from "../CustomComponents/CustomInputNumber";
import BuscarPaciente from "../Paciente/BuscarPaciente";
import {
    Estabelecimento,
    Orcamento,
    Especialista,
    Pacote,
    DominioValor,
    Agendamento,
    Agenda,
} from "./../../middleware";
// import { required, requiredIf, minLength } from "vuelidate/lib/validators";
import { required, minLength } from "vuelidate/lib/validators";
import { pCalendarLocale_ptbr } from "./../../utils";
import BuscarProcedimento from "./BuscarProcedimento";
import moment from "moment";
import Protocolo from "../../middleware/controllers/Protocolo";

export default {
    components: {
        ProgressBar,
        Panel,
        InputText,
        InputNumber,
        Button,
        Password,
        ProgressSpinner,
        Calendar,
        Textarea,
        InputMask,
        SelectButton,
        Dropdown,
        PickList,
        DataTable,
        Column,
        MultiSelect,
        Paginator,
        Dialog,
        BuscarPaciente,
        InputSwitch,
        CustomInputNumber,
        Card,
        BuscarProcedimento
    },
    directives: { tooltip: Tooltip },
    created() {
        this.refresh();
    },
    watch: {
        "model.ie_tipo_pacote": function(val){
            if(val != "P")
            this.model.ie_subtipo = null;
        },
        "model.itemList": function(list) {
            if (list) {
                this.model.showItemList = list.map(item => ({
                    nm_estabelecimento: item.nm_estabelecimento,
                    cd_cbhpm: item.cd_cbhpm,
                    nm_procedimento: item.nm_procedimento,
                    qtd: item.qtd,
                    valor_original: "R$ " + item.valor_original.toString(),
                    nr_valor_negociado:
                        "R$ " + item.nr_valor_negociado.toString(),
                    nr_valor_tarifa: "R$ " + item.nr_valor_tarifa.toString(),
                    id: item.id,
                    cd_agenda: item.cd_agenda,
                    cd_agenda_f: item.cd_agenda_f,
                    ie_grupo: item.ie_grupo
                }));
                this.attPriceList();
            }
        },
        "model.procedimentos": function(procedimentos) {
            procedimentos.forEach(procedimento => {
                procedimento.opme_f = `${this.$root.formatPrice(
                    procedimento.nr_valor_opme
                )}`;
                if (procedimento.ds_opme_descricao)
                    procedimento.opme_f += ` (${procedimento.ds_opme_descricao})`;
                procedimento.taxa_f = `${this.$root.formatPrice(
                    procedimento.nr_valor_taxa
                )}`;
                if (procedimento.ds_taxa_descricao)
                    procedimento.taxa_f += ` (${procedimento.ds_taxa_descricao})`;
                procedimento.exames_f = `${this.$root.formatPrice(
                    procedimento.nr_valor_exames
                )}`;
                if (procedimento.ds_exames_descricao)
                    procedimento.exames_f += ` (${procedimento.ds_exames_descricao})`;
                procedimento.hemoderivados_f = `${this.$root.formatPrice(
                    procedimento.nr_valor_hemoderivados
                )}`;
                if (procedimento.ds_hemoderivados_descricao)
                    procedimento.hemoderivados_f += ` (${procedimento.ds_hemoderivados_descricao})`;
            });
        },
        "model.ie_conta_aberto": function(val) {
            this.model.ie_pacote = !val;
        },
        "model.ie_pacote": function(val) {
            this.model.ie_conta_aberto = !val;
        },
        "model.preco.fim_vig": function(val) {
            let valido = true;
            if (this.model.priceList.length > 0) {
                this.model.priceList.forEach(item => {
                    if (
                        moment(this.model.preco.inicio_vig).isBefore(
                            item.inicio_vig
                        ) &&
                        moment(val).isBefore(item.fim_vig) &&
                        moment(val).isAfter(this.model.preco.inicio_vig)
                    ) {
                        valido = false;
                    }
                });
            }
            this.model.fim_vigencia_valido = valido;
        },
        "model.preco.inicio_vig": function(val) {
            let valido = true;
            if (this.model.priceList.length > 0) {
                this.model.priceList.forEach(item => {
                    if (
                        moment(val).isAfter(item.inicio_vig) &&
                        (moment(val).isBefore(item.fim_vig) ||
                            item.fim_vig == null)
                    ) {
                        valido = false;
                    }
                });
            }
            this.model.inicio_vigencia_valido = valido;
        },
        waiting: function(val) {
            if (!val) {
                window.setTimeout(() => {
                    if (this.$refs.buscarPaciente)
                        this.$refs.buscarPaciente.onSelectPessoaFisica = () => {
                            this.onSelectPessoaFisica();
                        };
                });
            }
        }
    },
    computed: {
        preco_original: function() {
            return this.model.itemList.reduce((ac, item) => {
                return (ac += item.valor_original * item.qtd);
            }, 0);
        },
        valor_estabelecimento: function() {
            return this.model.itemList.reduce((ac, item) => {
                return (ac += item.nr_valor_negociado * item.qtd);
            }, 0);
        },
        valor_medclub: function() {
            return this.model.itemList.reduce((ac, item) => {
                return (ac += item.nr_valor_tarifa * item.qtd);
            }, 0);
        },
        desconto_medclub: function() {
            if (this.model.preco.tipo_desconto_med == "VR")
                return this.model.preco.desconto_med;
            return (this.valor_medclub * this.model.preco.desconto_med) / 100;
        },
        desconto_parceiro: function() {
            if (this.model.preco.tipo_desconto_parc == "VR")
                return this.model.preco.desconto_parc;
            return (
                (this.valor_estabelecimento * this.model.preco.desconto_parc) /
                100
            );
        },
        desconto_total: function() {
            return this.desconto_medclub + this.desconto_parceiro;
        },
        preco_final: function() {
            return this.preco_original - this.desconto_total;
        }
    },
    data() {
        return {
            model: {
                id: 0,
                data_pacote: {
                    codigo: null,
                    descricao: null,
                    sigla: null,
                    sinonimo: [],
                    preparo: null,
                    termo: null,
                    obs: null
                },
                preco: {
                    tipo_desconto_med: "VR",
                    tipo_desconto_parc: "VR",
                    desconto_med: 0,
                    desconto_parc: 0,
                    inicio_vig: null,
                    fim_vig: null
                },
                itemList: [],
                showItemList: [],
                priceList: [],
                editPriceItem: false,
                ie_tipo_pacote: null,
                ie_subtipo: null,
                multi_estabelecimento: true,
                inicio_vigencia_valido: true,
                fim_vigencia_valido: true,
                descricao_valida: true,
                dialogProcedimento: false,
                procedimentos_selecionado: [],
                estabelecimentos_selecionados: [],
                todos_estabelecimentos: [],
                todos_procedimentos: [],
                cd_estabelecimento: null,
                cd_especialidades: [],
                procedimentos: [],

            },
            filters: {
                procedimentos: ""
            },
            options: {
                ie_tipo_pacote: [],
                ie_subtipo:[],
                tipo_desconto: [],
                procedimentos: [],
                estabelecimentos: [],
                especialistas: [],
                especialidades: [],
                especialidades_agendas: [],
                tipo_paciente: [
                    { label: "Cadastrado", value: "cadastrado" },
                    { label: "Sem cadastro", value: "sem-cadastro" }
                ],
                cd_orcamento_situacao: [
                    { label: "Em elaboração", value: 1 },
                    { label: "Pagamento pendente", value: 2 }
                ],
                ie_tipo_preco: [
                    { label: "A partir de", value: "A" },
                    { label: "Valor Fixo", value: "F" },
                    { label: "Não exibir", value: "N" }
                ],
                especialidade: [],
                agendas: []
            },
            ptbr: pCalendarLocale_ptbr,
            waitingSave: false,
            sinonimo_error: false,
            waitingProcedimentos: false,
            waiting: true,
            waitingPacote: false,
            waitingOrcamento: false,
            waitingEspecialista: false,
            waitingEspecialidades: false,
            submitted: false,
            submitted_preco: false,
            formProcedimento: false,
            dialogRemover: false,
            dialogRemover_data: null,
            dialogAgenda: false,
            dialogAgenda_data: null,
            especialidadeSelecionada: null,
            agendaSelecionada: [],
            agendasList: [],
            waitingAgenda: false,
            isOrcamento: false,
            editOrcamento: false,
            tipoPaciente: "cadastrado",
            especialistas: [],
            labelSubmit() {
                return this.isOrcamento
                    ? this.editOrcamento
                        ? "Salvar"
                        : "Criar orçamento"
                    : "Salvar";
            },
            dialogHistorico: false,
            waitingHistorico: false,
            waitingEspecialidade: false,
            historico: []
        };
    },
    validations() {
        let v = {
            model: {
                ie_tipo_pacote: { required },
                data_pacote: {
                    descricao: { required, minLength: minLength(2) }
                }
            }
        };
        return v;
    },
    methods: {
        refresh() {
            this.waiting = true;

            let id = 0;
            if (!isNaN(this.$route.params.id)) {
                id = parseInt(this.$route.params.id);
                this.model.id = parseInt(this.$route.params.id);
            }

            Agendamento.getEspecialidades().then(response => {
				if(response.status == 200) {
					this.options.especialidades.push({ text: '- Selecione -', value: null })
					response.data.forEach(espe => {
						this.options.especialidades.push({ value: espe.id, text: espe.nm_especialidade });
					});
				}
			})

            Estabelecimento.findAllClean()
                .then(response => {
                    if(([200,201,204]).includes(response.status)){
                        this.options.estabelecimentos = response.data;
                    }
                })

            DominioValor.findAll({
                ds_mnemonico: ["TIPO_PACOTE", "TIPO_VALOR", "SUBTIPO_PACOTE"]
            }).then(response => {
                this.options.ie_tipo_pacote = response.data.TIPO_PACOTE.valores.map(item => ({
                        label: item.ds_valor,
                        value: item.ie_valor
                    })
                );
                this.options.tipo_desconto = response.data.TIPO_VALOR.valores.map(item => ({
                        label: item.ds_valor,
                        value: item.ie_valor
                    })
                );
                this.options.ie_subtipo = response.data.SUBTIPO_PACOTE.valores.map(item => ({
                    label: item.ds_valor,
                    value: item.ie_valor
                }))
            });

            if (id != 0) {
                this.waitingPacote = true;
                Pacote.find(this.model.id).then(response => {
                    this.waitingPacote= false
                    if(([200, 201]).includes(response.status)){
                        this.model.multi_estabelecimento = response.data.ie_multi_estabelecimento;
                        this.model.ie_tipo_pacote = response.data.ie_tipo_pacote;
                        this.model.ie_subtipo = response.data.ie_subtipo;
                        this.model.preparo = response.data.ds_preparo;
                        this.model.termo = response.data.ds_termo_aceite;
                        this.model.obs = response.data.ds_observacao;
                        (this.model.nm_usuario_cri =
                            response.data.nm_usuario_cri),
                            (this.model.dt_criado_f = moment(
                                response.data.dt_criado
                            ).format("DD/MM/YYYY")),
                            (this.model.nm_usuario_edi =
                                response.data.nm_usuario_edi),
                            (this.model.dt_atualizado_f = moment(
                                response.data.dt_atualizado
                            ).format("DD/MM/YYYY")),
                            (this.model.data_pacote = {
                                codigo: response.data.cd_pacote,
                                descricao: response.data.ds_pacote,
                                sigla: response.data.ie_sigla,
                                sinonimo: response.data.ie_sinonimo
                            });
                        let auxList = [];
                        response.data.itens_pacote.forEach(item => {
                            const auxIndex = auxList.findIndex(
                                i =>
                                    i.cd_cbhpm ==
                                    item.cd_procedimento_estabelecimento
                                        .cd_procedimento
                            );
                            if (response.data.ie_multi_estabelecimento || auxIndex == -1) {
                                auxList.push({
                                    // nm_estabelecimento:
                                    //     item.cd_procedimento_estabelecimento
                                    //         .cd_estabelecimento.nm_fantasia,
                                    nm_estabelecimento: item.cd_procedimento_estabelecimento.nm_fantasia,
                                    cd_cbhpm:
                                        item.cd_procedimento_estabelecimento
                                            .cd_procedimento,
                                    nm_procedimento: `${
                                        item.cd_procedimento_estabelecimento
                                            .cd_procedimento
                                    }
                                    - ${
                                        item.cd_procedimento_estabelecimento
                                            .cd_procedimento == "10101012"
                                            ? item
                                                    .cd_procedimento_estabelecimento
                                                    .ds_procedimento
                                            : item
                                                    .cd_procedimento_estabelecimento
                                                    .nm_procedimento
                                    }`,
                                    qtd: item.nr_quantidade,
                                    cd_agenda: item.cd_agenda,
                                    cd_agenda_f: item.cd_agenda.map(item => ({ value: item.id, text: item.nm_especialista })),
                                    valor_original:
                                        +item.cd_procedimento_estabelecimento
                                            .nr_valor_negociado +
                                        +item.cd_procedimento_estabelecimento
                                            .nr_valor_tarifa,
                                    nr_valor_negociado: +item
                                        .cd_procedimento_estabelecimento
                                        .nr_valor_negociado,
                                    nr_valor_tarifa: +item
                                        .cd_procedimento_estabelecimento
                                        .nr_valor_tarifa,
                                    id: item.id,
                                    ie_grupo: item.cd_procedimento_estabelecimento.ie_grupo,
                                    itensId: [{
                                        id: item.id,
                                        idProcedEstab: item.cd_procedimento_estabelecimento.id
                                        }]
                                });
                            } else {
                                auxList[auxIndex].nm_estabelecimento =
                                    auxList[auxIndex].nm_estabelecimento +
                                    ", " +
                                    item.cd_procedimento_estabelecimento
                                        .cd_estabelecimento.nm_fantasia;
                                auxList[auxIndex].itensId.push({
                                    id: item.id,
                                    idProcedEstab:
                                        item.cd_procedimento_estabelecimento.id
                                });
                            }
                        });
                        this.model.itemList = auxList;
                        // if (this.model.itemList.length == 0) {
                        //     this.model.itemList = auxList;
                        // }
                        let auxEstab = [];
                        if (this.model.multi_estabelecimento) {
                            auxEstab = this.model.itemList.map(
                                item => item.nm_estabelecimento
                            );
                        } else {
                            this.model.itemList.forEach(item => {
                                auxEstab = auxEstab.concat(
                                    item.nm_estabelecimento
                                );
                            });
                        }
                        this.model.priceList = response.data.valores_pacote.map(
                            item => ({
                                estabelecimento: auxEstab
                                    .filter(
                                        (este, i) => auxEstab.indexOf(este) == i
                                    )
                                    .join(" - "),
                                vigencia: item.dt_fim_vigencia
                                    ? moment(item.dt_inicio_vigencia).format(
                                            "DD/MM/YYYY"
                                        ) +
                                        " - " +
                                        moment(item.dt_fim_vigencia).format(
                                            "DD/MM/YYYY"
                                        )
                                    : moment(item.dt_inicio_vigencia).format(
                                            "DD/MM/YYYY"
                                        ),
                                preco: "R$ " + +item.nr_preco,
                                preco_calc: +item.nr_preco,
                                preco_original_i: +this.preco_original.toFixed(2),
                                preco_original:
                                    "R$ " + this.preco_original.toFixed(2),
                                taxa_medclub:
                                    "R$ " + this.valor_medclub.toFixed(2),
                                valor_negociado:
                                    "R$ " + this.valor_estabelecimento.toFixed(2),
                                desconto_medclub:
                                    item.ie_tipo_desconto_medclub == "VR"
                                        ? "R$ " + +item.nr_desconto_medclub
                                        : item.nr_desconto_medclub + "%",
                                novo_desconto_medclub:
                                    item.ie_tipo_desconto_medclub == "VR"
                                        ? "R$ " +
                                            (
                                                this.valor_medclub -
                                                +item.nr_desconto_medclub
                                            ).toFixed(2)
                                        : "R$ " +
                                            (
                                                this.valor_medclub -
                                                (this.valor_medclub *
                                                    +item.nr_desconto_medclub) /
                                                    100
                                            ).toFixed(2),
                                desconto_parceiro:
                                    item.ie_tipo_desconto_parceiro == "VR"
                                        ? "R$ " + +item.nr_desconto_parceiro
                                        : item.nr_desconto_parceiro + "%",
                                novo_desconto_parceiro:
                                    item.ie_tipo_desconto_parceiro == "VR"
                                        ? "R$ " +
                                            (
                                                this.valor_estabelecimento -
                                                +item.nr_desconto_parceiro
                                            ).toFixed(2)
                                        : "R$ " +
                                            (
                                                this.valor_estabelecimento -
                                                (this.valor_estabelecimento *
                                                    +item.nr_desconto_parceiro) /
                                                    100
                                            ).toFixed(2),
                                desconto_medclub_calc: +item.nr_desconto_medclub,
                                desconto_parceiro_calc: +item.nr_desconto_parceiro,
                                tipo_desconto_med:
                                    item.ie_tipo_desconto_medclub,
                                tipo_desconto_parc:
                                    item.ie_tipo_desconto_parceiro,
                                inicio_vig: item.dt_inicio_vigencia,
                                fim_vig: item.dt_fim_vigencia? moment(item.dt_fim_vigencia): null,
                                id: item.id
                            })
                        );
                    }
                    else{
                        this.$toast.error("Erro no servidor", {
                            duration: 3000
                        });
                    }
                })
            }
            this.waiting = false;
        },
        changeMulti() {
            this.model.itemList = [];
            this.model.priceList = [];
        },
        getMinDate() {
            return moment()._d;
        },
        setMinDate() {
            this.model.preco.inicio_vig = moment()._d;
        },
        setMaxDate() {
            // this.model.preco.fim_vig = moment()._d;
        },
        onAddPrice() {
            this.model.submitted_preco = true;
            if (this.model.preco.inicio_vig) {
                let auxEstab = [];
                if (this.model.multi_estabelecimento) {
                    auxEstab = this.model.itemList.map(
                        item => item.nm_estabelecimento
                    );
                } else {
                    this.model.itemList.forEach(item => {
                        auxEstab = auxEstab.concat(item.nm_estabelecimento);
                    });
                }
                this.model.priceList.push({
                    estabelecimento: auxEstab
                        .filter((este, i) => auxEstab.indexOf(este) == i)
                        .join(" - "),
                    vigencia: this.model.preco.fim_vig
                        ? moment(this.model.preco.inicio_vig).format(
                              "DD/MM/YYYY"
                          ) +
                          " - " +
                          moment(this.model.preco.fim_vig).format("DD/MM/YYYY")
                        : moment(this.model.preco.inicio_vig).format(
                              "DD/MM/YYYY"
                          ),
                    preco: "R$ " + this.preco_final.toFixed(2),
                    preco_calc: this.preco_final,
                    preco_original: "R$ " + this.preco_original.toFixed(2),
                    taxa_medclub: "R$ " + this.valor_medclub.toFixed(2),
                    valor_negociado:
                        "R$ " + this.valor_estabelecimento.toFixed(2),
                    desconto_medclub:
                        this.model.preco.tipo_desconto_med == "VR"
                            ? "R$ " + this.model.preco.desconto_med.toFixed(2)
                            : this.model.preco.desconto_med + "%",
                    novo_desconto_medclub:
                        this.model.preco.tipo_desconto_med == "VR"
                            ? "R$ " +
                              (
                                  this.valor_medclub -
                                  this.model.preco.desconto_med
                              ).toFixed(2)
                            : "R$ " +
                              (
                                  this.valor_medclub -
                                  (this.valor_medclub *
                                      this.model.preco.desconto_med) /
                                      100
                              ).toFixed(2),
                    desconto_parceiro:
                        this.model.preco.tipo_desconto_parc == "VR"
                            ? "R$ " + this.model.preco.desconto_parc.toFixed(2)
                            : this.model.preco.desconto_parc + "%",
                    novo_desconto_parceiro:
                        this.model.preco.tipo_desconto_parc == "VR"
                            ? "R$ " +
                              (
                                  this.valor_estabelecimento -
                                  this.model.preco.desconto_parc
                              ).toFixed(2)
                            : "R$ " +
                              (
                                  this.valor_estabelecimento -
                                  (this.valor_estabelecimento *
                                      this.model.preco.desconto_parc) /
                                      100
                              ).toFixed(2),
                    desconto_medclub_calc: this.model.preco.desconto_med,
                    desconto_parceiro_calc: this.model.preco.desconto_parc,
                    tipo_desconto_med: this.model.preco.tipo_desconto_med,
                    tipo_desconto_parc: this.model.preco.tipo_desconto_parc,
                    inicio_vig: this.model.preco.inicio_vig,
                    fim_vig: this.model.preco.fim_vig,
                    id: this.model.preco.id
                });
                this.model.preco = {
                    tipo_desconto_med: "VR",
                    tipo_desconto_parc: "VR",
                    desconto_med: 0,
                    desconto_parc: 0,
                    inicio_vig: null,
                    fim_vig: null
                };
            } else {
                if (!this.model.preco.inicio_vig)
                    this.model.inicio_vigencia_valido = false;
                // if (!this.model.preco.fim_vig)
                //     this.model.fim_vigencia_valido = false;
            }
        },
        onDeleteItem(id) {
            this.model.itemList.splice(
                this.model.itemList.findIndex(item => item.id == id),
                1
            );
        },
        onDeletePriceItem(vigencia) {
            this.model.priceList.splice(
                this.model.priceList.findIndex(
                    item => item.vigencia == vigencia
                ),
                1
            );
        },
        onEditPriceItem(data) {
            this.model.editPriceItem = data;
            this.model.preco.tipo_desconto_med = data.tipo_desconto_med;
            this.model.preco.tipo_desconto_parc = data.tipo_desconto_parc;
            this.model.preco.desconto_med = data.desconto_medclub_calc;
            this.model.preco.desconto_parc = data.desconto_parceiro_calc;
            this.model.preco.inicio_vig = moment(data.inicio_vig).toDate();
            this.model.preco.fim_vig = data.fim_vig
                ? moment(data.fim_vig).toDate()
                : null;
        },
        onSaveEdit() {
            this.submitted = true;

            const index = this.model.priceList.findIndex(
                item => item.id == this.model.editPriceItem.id
            );
            this.model.priceList[index] = {
                ...this.model.priceList[index],
                vigencia:
                    moment(this.model.preco.inicio_vig).format("DD/MM/YYYY") +
                    " - " +
                    moment(this.model.preco.fim_vig).format("DD/MM/YYYY"),
                preco: "R$ " + this.preco_final.toFixed(2),
                preco_calc: this.preco_final,
                preco_original: "R$ " + this.preco_original.toFixed(2),
                taxa_medclub: "R$ " + this.valor_medclub.toFixed(2),
                valor_negociado: "R$ " + this.valor_estabelecimento.toFixed(2),
                desconto_medclub:
                    this.model.preco.tipo_desconto_med == "VR"
                        ? "R$ " + this.model.preco.desconto_med.toFixed(2)
                        : this.model.preco.desconto_med + "%",
                novo_desconto_medclub:
                    this.model.preco.tipo_desconto_med == "VR"
                        ? "R$ " +
                          (
                              this.valor_medclub - this.model.preco.desconto_med
                          ).toFixed(2)
                        : "R$ " +
                          (
                              this.valor_medclub -
                              (this.valor_medclub *
                                  this.model.preco.desconto_med) /
                                  100
                          ).toFixed(2),
                desconto_parceiro:
                    this.model.preco.tipo_desconto_parc == "VR"
                        ? "R$ " + this.model.preco.desconto_parc.toFixed(2)
                        : this.model.preco.desconto_parc + "%",
                novo_desconto_parceiro:
                    this.model.preco.tipo_desconto_parc == "VR"
                        ? "R$ " +
                          (
                              this.valor_estabelecimento -
                              this.model.preco.desconto_parc
                          ).toFixed(2)
                        : "R$ " +
                          (
                              this.valor_estabelecimento -
                              (this.valor_estabelecimento *
                                  this.model.preco.desconto_parc) /
                                  100
                          ).toFixed(2),
                desconto_medclub_calc: this.model.preco.desconto_med,
                desconto_parceiro_calc: this.model.preco.desconto_parc,
                tipo_desconto_med: this.model.preco.tipo_desconto_med,
                tipo_desconto_parc: this.model.preco.tipo_desconto_parc,
                inicio_vig: this.model.preco.inicio_vig,
                fim_vig: this.model.preco.fim_vig
            };
            this.model.preco = {
                tipo_desconto_med: "VR",
                tipo_desconto_parc: "VR",
                desconto_med: 0,
                desconto_parc: 0,
                inicio_vig: null,
                fim_vig: null
            };
            this.model.editPriceItem = false;
        },
        onCancelAdd() {
            this.model.editPriceItem = false;
            this.model.preco = {
                tipo_desconto_med: "VR",
                tipo_desconto_parc: "VR",
                desconto_med: 0,
                desconto_parc: 0,
                inicio_vig: null,
                fim_vig: null
            };
        },
        onChangeQtd(data) {
            const index = this.model.itemList.findIndex(
                item => item.id == data.id
            );
            this.model.itemList[index].qtd = data.qtd;
            this.attPriceList();
        },
        removerProcedimento(idx) {
            this.model.procedimentos.splice(idx, 1);
            this.dialogRemover = false;
        },
        onSelectPessoaFisica() {
            this.dialogHistorico = true;
            this.waitingHistorico = true;
            this.historico = [];
            Orcamento.findAll({
                nr_cpf: this.$refs.buscarPaciente.model.nr_cpf
            }).then(response => {
                this.waitingHistorico = false;
                if (response.status === 200) {
                    if (response.data.length)
                        this.historico = this.model.id
                            ? response.data.filter(i => i.id != this.model.id)
                            : response.data;
                    else {
                        this.dialogHistorico = false;
                        this.$toast.info("Paciente sem orçamentos anteriores", {
                            duration: 3000
                        });
                    }
                }
            });
        },
        getEspecialistas() {
            this.waitingEspecialista = true;
            Especialista.findByEstabelecimento(
                this.model.cd_estabelecimento
            ).then(response => {
                this.waitingEspecialista = false;
                if (response.status === 200) this.especialistas = response.data;
            });
        },
        getEspecialidades() {
            this.waitingEspecialidades = true;
            Protocolo.getEspecialidades().then(response => {
                this.waitingEspecialidades = false;
                if ([200, 201, 204].includes(response.status)) {
                    this.options.especialidades = response.data.map(esp => {
                        return { text: esp.nm_especialidade, value: esp.id };
                    });
                }
            });
        },
        async handleSubmit() {
            this.submited = true;
            // if(!this.model?.ie_sinonimo || !this.model?.ie_sinonimo?.length) this.sinonimo_error = true;
            if (!this.model.data_pacote.descricao) {
                // alert("Informe a descrição do pacote");
                this.model.descricao_valida = false;
            } else {
                let datasend = {
                    id: this.model.id == 0 ? null : this.model.id,
                    cd_pacote: this.model.data_pacote.codigo,
                    ds_pacote: this.model.data_pacote.descricao,
                    ie_multi_estabelecimento: this.model.multi_estabelecimento,
                    ie_sigla: this.model.data_pacote.sigla,
                    ie_sinonimo:
                        Array.isArray(this.model.data_pacote.sinonimo) ?
                            this.model.data_pacote.sinonimo : [this.model.data_pacote.sinonimo],
                    ie_subtipo: this.model.ie_subtipo,
                    ie_tipo_pacote: this.model.ie_tipo_pacote,
                    ds_preparo: this.model.preparo,
                    ds_termo_aceite: this.model.termo,
                    ds_observacao: this.model.obs,
                    itens_pacote: this.model.multi_estabelecimento
                        ? this.model.itemList.map(item => {
                            let procEstab = item.id
                            if (this.model.id != 0 && item.itensId) {
                               procEstab = item.itensId[0].idProcedEstab
                            }
                            return {
                                nr_quantidade: item.qtd,
                                cd_agenda: item.cd_agenda,
                                cd_procedimento_estabelecimento: procEstab
                            }})
                        : this.desmembrarItems(),
                    valores_pacote: this.model.priceList.map(item => ({
                        id:
                            this.model.id == 0
                                ? null
                                : item.id
                                ? item.id
                                : null,
                        nr_preco: item.preco_calc,
                        nr_desconto: this.desconto_total,
                        ie_tipo_desconto_medclub: item.tipo_desconto_med,
                        nr_desconto_medclub: item.desconto_medclub_calc,
                        ie_tipo_desconto_parceiro: item.tipo_desconto_parc,
                        nr_desconto_parceiro: item.desconto_parceiro_calc,
                        dt_inicio_vigencia: moment(item.inicio_vig).format(
                            "YYYY-MM-DDTHH:mm:ss"
                        ),
                        dt_fim_vigencia: item.fim_vig
                            ? moment(item.fim_vig).format("YYYY-MM-DDTHH:mm:ss")
                            : undefined
                    }))
                };
                this.waitingSave = true
                await Pacote.save(datasend).then(response => {
                    this.submitted = false;
                    this.waitingSave = false
                    if (response.data.status == "CREATED") {
                        this.$router.push(`/pacotes/`);
                    } else if (response.status == 200) {
                        this.$toast.success("Edição realizada com Sucesso", {
                            duration: 3000
                        });
                    } else {
                        if (response) {
                            Object.keys(response.data.message).forEach(key => {
                                if (key == "ie_sinonimo")
                                    this.$toast.error(
                                        "Sinônimos: " + response.data.message[key].join(" "),
                                        {
                                            duration: 3000
                                        }
                                    );
                                if (key == "cd_pacote")
                                    this.$toast.error(
                                        "Código: " + response.data.message[key].join(" "),
                                        {
                                            duration: 3000
                                        }
                                    );
                                if (key == "ds_observacao")
                                    this.$toast.error(
                                        "Observaçao: " + response.data.message[key].join(" "),
                                        {
                                            duration: 3000
                                        }
                                    );
                                if (key == "ds_pacote")
                                    this.$toast.error(
                                        "Pacote: " + response.data.message[key].join(" "),
                                        {
                                            duration: 3000
                                        }
                                    );
                                if (key == "ds_preparo")
                                    this.$toast.error(
                                        "Preparo: " + response.data.message[key].join(" "),
                                        {
                                            duration: 3000
                                        }
                                    );
                                if (key == "ds_termo_aceite")
                                    this.$toast.error(
                                        "Termo: " + response.data.message[key].join(" "),
                                        {
                                            duration: 3000
                                        }
                                    );
                                if (key == "ie_sigla")
                                    this.$toast.error(
                                        "Sigla: " + response.data.message[key].join(" "),
                                        {
                                            duration: 3000
                                        }
                                    );
                                if (key == "ie_tipo_pacote")
                                    this.$toast.error(
                                        "Tipo de Pacote: " + response.data.message[key].join(" "),
                                        {
                                            duration: 3000
                                        }
                                    );
                                if (key == "itens_pacote")
                                    this.$toast.error(
                                        "Procedimentos: " + response.data.message[key].detail,
                                        {
                                            duration: 3000
                                        }
                                    );
                                if (key == "valores_pacote")
                                    this.$toast.error(
                                        "Preço: " + response.data.message[key].detail,
                                        {
                                            duration: 3000
                                        }
                                    );
                            });
                        }
                    }
                });
            }
        },
        desmembrarItems() {
            let auxList = [];
            this.model.itemList.forEach(item => {
                if (this.model.id != 0) {
                    item.itensId.forEach(i => {
                        auxList.push({
                            id: i.id,
                            nr_quantidade: item.qtd,
                            cd_procedimento_estabelecimento: i.idProcedEstab
                        });
                    });
                } else {
                    item.id.forEach(i => {
                        auxList.push({
                            id: null,
                            nr_quantidade: item.qtd,
                            cd_procedimento_estabelecimento: i
                        });
                    });
                }
            });
            return auxList;
        },
        attPriceList() {
            if (this.model.priceList.length > 0) {
                this.model.priceList.forEach((item, index) => {
                    if (moment(item.inicio_vig).isAfter(moment(), "day")) {
                        this.model.priceList[
                            index
                        ].inicio_vig = moment().subtract(1, "days");
                    }
                    if (moment(item.fim_vig).isAfter(moment(), "day")) {
                        this.model.priceList[index].fim_vig = moment().subtract(
                            1,
                            "days"
                        );
                    }
                });
            }
        },
        selsectEspecialidade(){
            this.agendaSelecionada = [];
            let params = {
                paginacao:false,
                // order:"cd_especialista__nm_especialista",
                // ie_tipo_agenda:"C",
                cd_especialidade:this.especialidadeSelecionada,
                cd_estabelecimento:this.dialogAgenda_data.cd_estabelecimento,
                // ie_atende_medclub_empresa: false
            }
            this.waitingAgenda = true;
            Agenda.findAll(params).then(response => {
                this.waitingAgenda = false;
                if (([200,201]).includes(response.status)) {
                    this.options.agendas = response.data.results
                        .filter(item => !this.agendasList.find(i => i.cd_especialista == item.id))
                        .map(item => ({ value: item.id, text: item.nm_especialista }))

                }else this.$toast.error("Erro na busca de agendas")
            })
        },
        AdicionarEspecialidade(){
            if(!this.agendaSelecionada.length){
                this.$toast.error("Selecione ao menos uma agenda")
                return
            }
            this.agendasList = [
                ...this.agendasList,
                ...this.agendaSelecionada.map(item => ({
                    nm_especialidade: this.options.especialidades.find(i => i.value == this.especialidadeSelecionada).text,
                    nm_especialista: this.options.agendas.find(i => i.value == item).text,
                    cd_especialidade: this.especialidadeSelecionada,
                    cd_especialista: item
                }))
            ]
            this.especialidadeSelecionada = null;
            this.agendaSelecionada = this.options.agendas = []
        },
        excluirEspecialidade(data){
            this.agendasList = this.agendasList.filter(item => item.cd_especialista != data.cd_especialista)
        },
        onSelectAgenda(data){
            this.dialogAgenda_data = data;
            this.dialogAgenda = true;
            this.agendasList = this.dialogAgenda_data.cd_agenda_f
            // if(this.dialogAgenda_data.cd_agenda)
            //     this.agendasList = this.dialogAgenda_data.cd_agenda.map(item => ({
            //         nm_especialidade: item.nm_especialidade,
            //         nm_especialista: item.nm_especialista,
            //         cd_especialidade:item.cd_especialidade,
            //         cd_especialista: item.id
            //     }))
        },
        onSaveAgendas(){
            let auxIndex = this.model.itemList.findIndex(item => item.id == this.dialogAgenda_data.id)
            this.model.itemList[auxIndex].cd_agenda = this.model.showItemList[auxIndex].cd_agenda = this.agendasList.map(item =>({
                id: item.cd_especialista,
                cd_especialidade: item.cd_especialidade,
                nm_especialidade: item.nm_especialidade,
                nm_especialista: item.nm_especialista
            }))
            this.dialogAgenda = false;
        },
        onHideAgendamentos(){
            this.especialidadeSelecionada = this.dialogAgenda_data = null;
            this.agendasList = this.agendaSelecionada =  this.options.agendas = []
        },
        calcularMaximo(minima){
            return moment(minima).add(6, 'months').toDate()
        }
    }
};
</script>
